import { responseParse } from '../../utils/responseParse';
import { authHeader } from '../../utils/authHeader';
import { API_HOST } from '../../constants';

const getRequestGetOptions = () => ({
  method: 'GET',
  mode: 'cors',
  headers: authHeader(),
});

const getRequestPostOptions = (deviceKey) => ({
  method: 'POST',
  mode: 'cors',
  headers: {
    Authorization: `Device ${deviceKey}`,
    'Content-Type': 'application/json',
  },
});

const getRequestPatchOptions = (deviceKey) => ({
  method: 'PATCH',
  mode: 'cors',
  headers: {
    Authorization: `Device ${deviceKey}`,
    'Content-Type': 'application/json',
  },
});

const getRequestDeleteOptions = (deviceKey) => ({
  method: 'DELETE',
  mode: 'cors',
  headers: {
    Authorization: `Device ${deviceKey}`,
    'Content-Type': 'application/json',
  },
});

export const patientsAPI = {

  // get all patients within an organization
  getPatientsByOrganizationId: async (organizationId) => {
    const response = await fetch(
      `${API_HOST}/api/organizations/patients/${organizationId}/`,
      getRequestGetOptions(),
    );
    return responseParse(response);
  },

  // get all patients within a customer
  getPatientsByCustomerId: async (customerId) => {
    const response = await fetch(
      `${API_HOST}/api/customers/patients/${customerId}/`,
      getRequestGetOptions(),
    );
    return responseParse(response);
  },

  // disenroll a patient on a specific device, after confirm code input from user
  disenrollPatientByDeviceKey: async (deviceKey, confirmCode) => {
    // make disenroll request
    const response = await fetch(
      `${API_HOST}/api/v1/patients/`,
      {
        ...getRequestDeleteOptions(deviceKey),
        body: JSON.stringify({
          confirm: confirmCode,
        }),
      },
    );

    // if request failed, return error
    if (response.status !== 200) return { error: response.statusText };
    const data = await response.json();
    if (data.code !== 200) return { error: data.message };

    // no error if request succeeded
    return { error: false };
  },

  // enroll a patient on a specific device
  enrollPatientByDeviceKey: async (deviceKey, patientInfo) => {
    // make new patient request
    const response1 = await fetch(
      `${API_HOST}/api/v1/patients/`,
      {
        ...getRequestPostOptions(deviceKey),
        body: JSON.stringify({ data: { patient: patientInfo } }),
      },
    );

    // if request1 failed, return error
    if (response1.status !== 200) return { error: response1.statusText };
    const data1 = await response1.json();
    if (data1.code !== 201) return { error: data1.message };

    // make enroll request
    const response2 = await fetch(
      `${API_HOST}/api/v1/patients/`,
      {
        ...getRequestPatchOptions(deviceKey),
        body: JSON.stringify({ data: { patient: patientInfo } }),
      },
    );

    // if request2 failed, return error
    if (response2.status !== 200) return { error: response2.statusText };
    const data2 = await response2.json();
    if (data2.code !== 201) return { error: data2.message };

    // no error if request succeeded
    return { error: false };
  },
};
