import { responseParse } from '../../utils/responseParse';
import { authHeader } from '../../utils/authHeader';
import { API_HOST } from '../../constants';

const getRequestGetOptions = () => ({
  method: 'GET',
  mode: 'cors',
  headers: authHeader(),
});

const getRequestPostOptions = () => ({
  method: 'POST',
  mode: 'cors',
  headers: { ...authHeader(), 'Content-Type': 'application/json' },
});

export const devicesAPI = {
  getDevices: async () => {
    const response = await fetch(`${API_HOST}/devices/`, getRequestGetOptions());
    return responseParse(response);
  },
  getDevicesByOrganizationId: async (payload) => {
    const response = await fetch(`${API_HOST}/api/organizations/${payload}/`, getRequestGetOptions());
    return responseParse(response);
  },
  notifyDeviceByUserId: async (payload) => {
    const response = await fetch(`${API_HOST}/api/notify/device/`, { ...getRequestPostOptions(), body: JSON.stringify({ device_id: payload.device_id, session_name: payload.session_name }) });
    return responseParse(response);
  },

  // access all devices for a certain customer
  getDevicesByCustomerId: async (payload) => {
    const response = await fetch(`${API_HOST}/api/customers/${payload}/`, getRequestGetOptions());
    return responseParse(response);
  },
};
