import { useSelector } from 'react-redux';
import { selectUser } from '../../store/slices/user';

/**
 * useAccess Hook
 * @returns {boolean}
 * @constructor
 */

export const useAccess = () => {
  const { token } = useSelector(selectUser);

  return !!token;
};
