import { createSlice } from '@reduxjs/toolkit';
import FileSaver from 'file-saver';
import { userSignOut } from '../user';
import { sessionsEventsToCsvApi } from '../../../services/sessionsEventsToCsv';

export const sessionsEventsToCsv = createSlice({
  name: 'sessionsEventsToCsv',
  initialState: { loading: false, error: false },
  reducers: {
    getSessionsEventsToCsvRequested: (state) => ({
      ...state,
      loading: true,
    }),
    getSessionsEventsToCsvFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
    }),
    getSessionsEventsToCsvSuccess: (state) => ({ ...state, loading: false, error: false }),
  },
  extraReducers: {
    [userSignOut]: () => ({ loading: false, error: false }),
  },
});

// ---- actions ----
export const {
  getSessionsEventsToCsvRequested,
  getSessionsEventsToCsvFailure,
  getSessionsEventsToCsvSuccess,
} = sessionsEventsToCsv.actions;

// ---- request actions ----
export const exportSessionsEventsToCsv = (payload) => async (dispatch) => {
  try {
    dispatch(getSessionsEventsToCsvRequested());
    const response = await sessionsEventsToCsvApi.getSessionsEvents(payload);
    if (response.status === 401) {
      dispatch(userSignOut());
    } else {
      response.blob().then((blob) => FileSaver.saveAs(blob, 'calls.csv'));
      dispatch(getSessionsEventsToCsvSuccess());
    }
  } catch (e) {
    dispatch(getSessionsEventsToCsvFailure(e.message));
  }
};

// ---- selectors ----
export const exportSessionsEventsToCsvStatus = (state) => ({
  exportToCsvLoading: state.sessionsEventsToCsv.loading,
  exportToCsvError: state.sessionsEventsToCsv.error,
});
