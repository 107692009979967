import { createSlice } from '@reduxjs/toolkit';
import { patientsAPI } from '../../../services/patients';
import { userSignOut } from '../user';

// patients state slice using patients API service
export const patients = createSlice({

  name: 'patients',

  initialState: {
    patients: [],
    loading: false,
    error: false,
  },

  reducers: {

    // set loading to true on start of request
    getPatientsRequested: (state) => ({
      ...state,
      loading: true,
    }),

    // set error to payload message on a request error
    getPatientsFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
    }),

    // store patient info on success
    getPatientsSuccess: (state, { payload }) => ({
      patients: payload,
      loading: false,
      error: false,
    }),

  },

  // clear all patients when user signs out
  extraReducers: {
    [userSignOut]: () => ({
      patients: [],
      loading: false,
      error: false,
    }),
  },
});

// ---- actions ----
export const { getPatientsSuccess, getPatientsRequested, getPatientsFailure } = patients.actions;

// ---- request actions ----

//get all patients within an organization
export const getPatientsByOrganizationId = (organizationId) => async (dispatch) => {
  try {

    //mark as loading
    dispatch(getPatientsRequested());

    //make request to api
    const response = await patientsAPI.getPatientsByOrganizationId(organizationId);

    //handle response
    if (response.data.error) {
      dispatch(getPatientsFailure(response.data.error));
    }
    if (response.status === 401) {
      dispatch(userSignOut());
    }
    else {
      dispatch(getPatientsSuccess(response.data));
    }
  } catch (e) {
    dispatch(getPatientsFailure(e.message));
  }
}

//get all patients within a customer
export const getPatientsByCustomerId = (customerId) => async (dispatch) => {
  try {

    //mark as loading
    dispatch(getPatientsRequested());

    //make request to api
    const response = await patientsAPI.getPatientsByCustomerId(customerId);

    //handle response
    if (response.data.error) {
      dispatch(getPatientsFailure(response.data.error));
    }
    if (response.status === 401) {
      dispatch(userSignOut());
    }
    else {
      dispatch(getPatientsSuccess(response.data));
    }
  } catch (e) {
    dispatch(getPatientsFailure(e.message));
  }
}

// ---- selectors ----

// select array of fetched patients
export const selectPatients = (state) => state.patients.patients;

// select status of patients request
export const selectPatientsStatus = (state) => ({
  loading: state.patients.loading,
  error: state.patients.error,
});

// select object of fetched patients, keyed to device id
export const selectPatientsKeyedToDeviceId = (state) => {
  const patient_obj = {};
  state.patients.patients.forEach((patient) => {
    patient_obj[patient.device] = patient;
  });
  return patient_obj;
}
