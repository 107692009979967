import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  full: {
    position: 'fixed',
    zIndex: '2',
    top: '0',
    left: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100vw',
    height: '100vh',
  },
  block: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

/**
 * Preloader Component
 * @param type
 * @param mb
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */

export const Preloader = ({ type, mb, ...props }) => {
  const classes = useStyles();

  return (<Box mb={mb} className={type === 'block' ? classes.block : classes.full}><CircularProgress {...props} /></Box>);
};

Preloader.propTypes = {
  type: PropTypes.string,
  mb: PropTypes.number,
};
