import { createSlice } from '@reduxjs/toolkit';
import { uid } from 'uid';

export const alert = createSlice({
  name: 'alert',
  initialState: {
    type: '',
    isInProgress: false,
    title: '',
  },
  reducers: {
    chatRecconecting: (state) => ({
      ...state,
      type: 'chatReconnecting',
      isInProgress: true,
      severity: 'info',
    }),

    chatRecconected: (state) => ({
      ...state,
      type: 'chatReconnected',
      isInProgress: false,
      severity: 'success',
    }),

    chatEnded: (state) => ({
      ...state,
      type: 'chatEnded',
      isInProgress: false,
      severity: 'error',
    }),

    customAlert: (state, { payload }) => ({
      ...state,
      type: 'custom',
      isInProgress: false,
      ...payload,
    }),

    closeAlert: () => ({
      type: '',
      isInProgress: false,
      title: '',
    }),
  },
});
// ---- action ----
export const {
  chatRecconecting, chatRecconected, customAlert, closeAlert, chatEnded,
} = alert.actions;

// ---- selectors ----
export const selectAlert = (state) => state.alert;

// alertStack
export const alertStack = createSlice({
  name: 'alertStack',
  initialState: {
    alerts: [],
  },

  reducers: {
    addCustomAlert: (state, { payload }) => ({
      action: 'add',
      alerts: [...state.alerts, {
        id: uid(),
        type: 'custom',
        isInProgress: false,
        timeout: 10000,
        title: '',
        ...payload,
      }],
    }),

    setAlerts: (state, { payload }) => ({
      action: 'set',
      alerts: payload,
    }),

    removeAlert: (state, { payload }) => ({
      action: 'remove',
      alerts: [state.alerts.filter((alertItem) => alertItem !== payload)],
    }),
  },
});
// ---- action ----
export const {
  addCustomAlert, setAlerts, removeAlert,
} = alertStack.actions;

// ---- selectors ----
export const selectAlertStack = (state) => state.alertStack;
