import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { user } from './slices/user';
import { videoChat } from './slices/videoChat';
import { devices } from './slices/devices';
import { videoChatModal } from './slices/videoChatModal';
import { organizations } from './slices/organizations';
import { callSessions } from './slices/callSessions';
import { users } from './slices/users';
import { callSessionEvents } from './slices/callSessionEvents';
import { sessionsEventsToCsv } from './slices/sessionsEventsToCsv';
import { alert, alertStack } from './slices/alerts';
import { patients } from './slices/patients';

export const store = configureStore({
  middleware: [...getDefaultMiddleware()],
  reducer: {
    user: user.reducer,
    devices: devices.reducer,
    users: users.reducer,
    videoChat: videoChat.reducer,
    videoChatModal: videoChatModal.reducer,
    organizations: organizations.reducer,
    callSessions: callSessions.reducer,
    callSessionEvents: callSessionEvents.reducer,
    sessionsEventsToCsv: sessionsEventsToCsv.reducer,
    alert: alert.reducer,
    patients: patients.reducer,
    alertStack: alertStack.reducer,
  },
});
