import { createSlice } from '@reduxjs/toolkit';
import { userSignOut } from '../user';
import { callSessionsApi } from '../../../services/callSessions';

export const callSessions = createSlice({
  name: 'callSessions',
  initialState: {
    callSessions: [], loading: false, error: false, activeSession: {},
  },
  reducers: {
    getCallSessionsRequested: (state) => ({
      ...state,
      loading: true,
    }),
    getCallSessionsFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
    }),
    getCallSessionsSuccess: (state, { payload }) => ({
      loading: false, error: false, callSessions: payload, activeSession: {},
    }),
    setSelectedCallSession: (state, { payload }) => ({
      ...state,
      activeSession: payload,
    }),
  },
  extraReducers: {
    [userSignOut]: () => ({
      callSessions: [], loading: false, error: false, activeOrganization: {},
    }),
  },
});

// ---- actions ----
export const {
  getCallSessionsSuccess, getCallSessionsRequested, getCallSessionsFailure, setSelectedCallSession,
} = callSessions.actions;

// ---- request actions ----
export const getCallSessions = (payload) => async (dispatch) => {
  try {
    dispatch(getCallSessionsRequested());
    const response = await callSessionsApi.getCallSessions(payload);
    if (response.data.error) {
      dispatch(getCallSessionsFailure(response.data.error));
    } if (response.status === 401) {
      dispatch(userSignOut());
    } else {
      dispatch(getCallSessionsSuccess(response.data));
    }
  } catch (e) {
    dispatch(getCallSessionsFailure(e.message));
  }
};

// ---- selectors ----
export const selectCallSessions = (state) => state.callSessions.callSessions;
export const selectCallSessionsStatus = (state) => ({
  loading: state.callSessions.loading,
  error: state.callSessions.error,
});
