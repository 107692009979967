import { createSlice } from '@reduxjs/toolkit';
import { userSignOut } from '../user';
import { callSessionsApi } from '../../../services/callSessions';

export const callSessionEvents = createSlice({
  name: 'callSessionEvents',
  initialState: { callSessionEvents: [], loading: false, error: false },
  reducers: {
    getCallSessionEventsRequested: (state) => ({
      ...state,
      loading: true,
    }),
    getCallSessionEventsFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
    }),
    getCallSessionEventsSuccess: (state, { payload }) => ({
      ...state, loading: false, error: false, callSessionEvents: payload,
    }),
  },
  extraReducers: {
    [userSignOut]: () => ({ callSessionEvents: [], loading: false, error: false }),
  },
});

// ---- actions ----
export const {
  getCallSessionEventsSuccess,
  getCallSessionEventsRequested,
  getCallSessionEventsFailure,
} = callSessionEvents.actions;

// ---- request actions ----
export const getCallSessionEvents = (payload) => async (dispatch) => {
  try {
    dispatch(getCallSessionEventsRequested());
    const response = await callSessionsApi.getCallSessionEvents(payload);
    if (response.data.error) {
      dispatch(getCallSessionEventsFailure(response.data.error));
    } if (response.status === 401) {
      dispatch(userSignOut());
    } else {
      dispatch(getCallSessionEventsSuccess(response.data));
    }
  } catch (e) {
    dispatch(getCallSessionEventsFailure(e.message));
  }
};

// ---- selectors ----
export const selectCallSessionEvents = (state) => state.callSessionEvents.callSessionEvents;
export const selectCallSessionEventsStatus = (state) => ({
  eventsLoading: state.callSessionEvents.loading,
  eventsError: state.callSessionEvents.error,
});
