import { createSlice } from '@reduxjs/toolkit';
import { userAPI } from '../../../services/user';

export const user = createSlice({
  name: 'user',
  initialState: {
    ...JSON.parse(localStorage.getItem('user')),
    loading: false,
    error: false
  },
  reducers: {
    userSignInRequested: (state) => ({
      ...state,
      loading: true,
    }),
    userSignInFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
    }),
    userSignInSuccess: (state, { payload }) => {
      localStorage.setItem('user', JSON.stringify(payload));
      console.log('USER SUSCCES - ', payload);
      console.log('GET FROM STORE', localStorage.getItem('user'));
      return ({ ...payload, loading: false, error: false });
    },
    // eslint-disable-next-line no-unused-vars
    userSignOut: (state, action) => {
      localStorage.removeItem('user');
      sessionStorage.removeItem('lastSelectedOrganization');
      document.location.href = '/#/';
      return {};
    }
  },
});

// ---- actions ----
export const {
  userSignInSuccess, userSignOut, userSignInRequested, userSignInFailure
} = user.actions;

// ---- request actions ----
export const signIn = (payload) => async (dispatch) => {
  try {
    dispatch(userSignInRequested());
    const response = await userAPI.signIn(payload);
    if (response.data.error) {
      dispatch(userSignInFailure(response.data.error));
    } else {
      dispatch(userSignInSuccess(response.data));
    }
  } catch (e) {
    dispatch(userSignInFailure(e.message));
  }
};

// ---- selectors ----
export const selectUser = (state) => ({ user: state.user, token: state.user.token });
export const selectUserStatus = (state) => ({
  loading: state.user.loading,
  error: state.user.error,
});
export const selectUserAdmin = (state) => ({
  isAdmin: !!state.user.admin,
  customerId: state.user.admin || 0
});