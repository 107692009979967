import { createSlice } from '@reduxjs/toolkit';

export const videoChatModal = createSlice({
  name: 'videoChatModal',
  initialState: {
    isVideoChatModalOpen: false,
  },
  reducers: {
    openVideoChatModal: (state) => ({
      ...state,
      isVideoChatModalOpen: true,
    }),
    closeVideoChatModal: (state) => ({
      ...state,
      isVideoChatModalOpen: false,
    }),
  },
});
// ---- action ----
export const { openVideoChatModal, closeVideoChatModal } = videoChatModal.actions;

// ---- selectors ----
export const selectIsVideoChatModalOpen = (state) => state.videoChatModal.isVideoChatModalOpen;
