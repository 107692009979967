import { createSlice } from '@reduxjs/toolkit';
import { organizationsAPI } from '../../../services/organizations';
import { userSignOut } from '../user';
import { getDevicesByOrganizationId } from '../devices';
import { getPatientsByOrganizationId } from '../patients';
import { getUsersByOrganizationId } from '../users';

export const organizations = createSlice({
  name: 'organizations',
  initialState: {
    organizations: [], loading: false, error: false, activeOrganization: '',
  },
  reducers: {
    getOrganizationsRequested: (state) => ({
      ...state,
      loading: true,
    }),
    getOrganizationsFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
    }),
    getOrganizationsSuccess: (state, { payload }) => ({
      loading: false,
      error: false,
      organizations: payload,
      activeOrganization: payload.length === 1 ? payload.slice(0, 1)[0].id : null,
    }),
    setSelectedOrganization: (state, { payload }) => ({
      ...state,
      activeOrganization: payload,
    }),
  },
  extraReducers: {
    [userSignOut]: () => ({
      organizations: [], loading: false, error: false, activeOrganization: '',
    }),
  },
});

// ---- actions ----
export const {
  getOrganizationsSuccess,
  getOrganizationsRequested,
  getOrganizationsFailure,
  setSelectedOrganization,
} = organizations.actions;

// ---- request actions ----
export const getOrganizations = (payload) => async (dispatch) => {
  try {
    dispatch(getOrganizationsRequested());
    const response = await organizationsAPI.getOrganizations(payload);
    if (response.data.error) {
      dispatch(getOrganizationsFailure(response.data.error));
    } if (response.status === 401) {
      dispatch(userSignOut());
    } else {
      dispatch(getOrganizationsSuccess(response.data));
      if (response.data.length === 1) {
        const defaultOrganizationId = response.data.slice(0, 1)[0].id;
        dispatch(getDevicesByOrganizationId(defaultOrganizationId));
        dispatch(getPatientsByOrganizationId(defaultOrganizationId));
        dispatch(getUsersByOrganizationId(defaultOrganizationId));
      }
    }
  } catch (e) {
    dispatch(getOrganizationsFailure(e.message));
  }
};

// ---- selectors ----
export const selectOrganizations = (state) => state.organizations.organizations;
export const selectOrganizationsStatus = (state) => ({
  loading: state.organizations.loading,
  error: state.organizations.error,
});
export const selectSelectedOrganization = (state) => state.organizations.activeOrganization;
