import { responseParse } from '../../utils/responseParse';
import { authHeader } from '../../utils/authHeader';
import { API_HOST } from '../../constants';

const requestGetOptions = () => ({
  method: 'GET',
  mode: 'cors',
  headers: authHeader(),
});
export const organizationsAPI = {
  getOrganizations: async () => {
    const response = await fetch(`${API_HOST}/api/organizations/`, requestGetOptions());
    return responseParse(response);
  },
  getOrganizationsByCustomerId: async (payload) => {
    const response = await fetch(`${API_HOST}/api/organizations/customer/${payload}/`, requestGetOptions());
    return responseParse(response);
  },
};
