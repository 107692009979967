import { responseParse } from '../../utils/responseParse';
import { authHeader } from '../../utils/authHeader';
import { API_HOST } from '../../constants';

const requestGetOptions = () => ({
  method: 'GET',
  mode: 'cors',
  headers: authHeader(),
});

const requestPostOptions = () => ({
  method: 'POST',
  mode: 'cors',
  headers: { ...authHeader(), 'Content-Type': 'application/json' },
});

const requestDeleteOptions = () => ({
  method: 'DELETE',
  mode: 'cors',
  headers: authHeader()
});

export const usersAPI = {
  getUsersByOrganizationId: async (payload) => {
    const response = await fetch(`${API_HOST}/api/users/organization/${payload}/`, requestGetOptions());
    return responseParse(response);
  },
  notifyUserByUserId: async (payload) => {
    const response = await fetch(`${API_HOST}/api/notify/`, { ...requestPostOptions(), body: JSON.stringify({ user_id: payload.user_id, session_name: payload.session_name }) });
    return responseParse(response);
  },
  setUserToken: async (payload) => {
    const response = await fetch(`${API_HOST}/api/set-update-user-token/`, { ...requestPostOptions(), body: JSON.stringify({ token: payload }) });
    return responseParse(response);
  },
  verifyUsers: async (payload) => {
    const response = await fetch(`${API_HOST}/api/verify-users/`, { ...requestPostOptions(), body: JSON.stringify({ ...payload }) });
    return responseParse(response);
  },
  getUsers: async (payload) => {
    const response = await fetch(`${API_HOST}/api/users/`, requestGetOptions());
    return responseParse(response);
  },
  createUser: async (payload) => {
    const response = await fetch(`${API_HOST}/api/create-user/`, {
      ...requestPostOptions(),
      body: JSON.stringify({
        username: payload.username,
        password: payload.password,
        first_name: payload.first_name,
        last_name: payload.last_name,
        email: payload.email,
      }),
    });
    return responseParse(response);
  },
  getUnverifiedUsers: async () => {
    const response = await fetch(`${API_HOST}/api/unverified-users/`, requestGetOptions());
    return responseParse(response);
  },
  deleteUnverifiedUser: async (payload) => {
    const response = await fetch(`${API_HOST}/api/unverified-user/${payload}/`, requestDeleteOptions());
    return responseParse(response);
  }
};
