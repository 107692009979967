import { responseParse } from '../../utils/responseParse';
import { API_HOST } from '../../constants';

export const userAPI = {
  signIn: async (payload) => {
    const requestOptions = {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: payload.username,
        password: payload.password,
      }),
    };
    const response = await fetch(`${API_HOST}/auth/`, requestOptions);
    return responseParse(response);
  },
};
