import { authHeader } from '../../utils/authHeader';
import { API_HOST } from '../../constants';

export const sessionsEventsToCsvApi = {
  getSessionsEvents: async (payload) => {
    const requestGetOptions = {
      method: 'GET',
      mode: 'cors',
      headers: authHeader(),
    };

    const requestPostOptions = {
      method: 'POST',
      mode: 'cors',
      headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };

    // eslint-disable-next-line no-return-await
    return await fetch(`${API_HOST}/api/csv/`, { ...requestPostOptions, body: JSON.stringify({ ...payload }) });
  },
};
