/* eslint-disable no-nested-ternary */
import React, { lazy, Suspense, useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, HashRouter , Route, Switch } from 'react-router-dom';
import { Preloader } from '../components/common/Preloader';
import { useAccess } from '../hooks/useAccess';
import { selectUserAdmin, selectUser } from '../store/slices/user';
import { API_HOST } from '../constants';

const SignIn = lazy(() => import('../pages/SignIn'));
const Dashboard = lazy(() => import('../pages/Dashboard'));
const NotFound = lazy(() => import('../pages/NotFound'));
const PasswordChange = lazy(() => import('../pages/PasswordChange'));
const PreviousCalls = lazy(() => import('../pages/PreviousCalls'));
const PasswordReset = lazy(() => import('../pages/PasswordReset'));
const EnrollDisenroll = lazy(() => import('../pages/EnrollDisenroll'));
const AddUser = lazy(() => import('../pages/AddUser'));
const SignUp = lazy(() => import('../pages/SignUp'));
const Support = lazy(() => import('../pages/Support'));

export const Router = () => {
  const isAuthenticated = useAccess();
  // check for user's admin status
  const { isAdmin } = useSelector(selectUserAdmin);
  const user = useSelector(selectUser);
  const socketRef = useRef(null);
  const [activeUsers, setActiveUsers] = useState([]);
  const [activeDevices, setActiveDevices] = useState([]);
  const reconnectInterval = useRef();
  // Starts handshake for websocket
  // Must be here because rerenders will create another new handshake
  // Uncomment this out when status is ready
  // const connect = () => {
  //   if (!user?.token) return;
  //   // Removes http:// or https://
  //   const host = API_HOST?.replace(/(^\w+:|^)\/\//, '');
  //   let websocket = 'wss';
  //   if (API_HOST?.startsWith('http://')) {
  //     websocket = 'ws';
  //   }
  //   socketRef.current = new WebSocket(`${websocket}://${host}/ws/providers/${user.user.user_id}/`);
  //   socketRef.current.onopen = e => {
  //     console.log('Web socket opened');
  //     socketRef.current.send(JSON.stringify({
  //       message: 'WebSocket Opened',
  //     }));
  //     clearInterval(reconnectInterval.current);
  //     setInterval(() => {
  //       if (socketRef?.current?.readyState === 3 || socketRef?.current?.readyState === 2) return
  //       socketRef.current.send(JSON.stringify({
  //         message: `Ping ${user.user.user_id}`,
  //       }));
  //     }, 5000);
  //   };
  //   socketRef.current.onclose = e => {
  //     console.log('Web socket closed');
  //     reconnectInterval.current = setInterval(() => {
  //       connect();
  //     }, 5000);
  //   };
  //   socketRef.current.onerror = e => console.log(e);
  //   socketRef.current.onmessage = e => {
  //     const msg = JSON.parse(e.data);
  //     if (msg.active_users) {
  //       setActiveUsers([...msg?.active_users]);
  //     }
  //     if (msg.active_devices) {
  //       setActiveDevices([...msg?.active_devices]);
  //     }
  //     if (msg.type === 'error') console.log(msg.data.message);
  //     else if (msg.type === 'chat_message') console.log(msg.data);
  //   };
  // }
  // useEffect(() => {
  //   connect();
  // }, [JSON.stringify(user)]);
  return (
      <HashRouter>
        <Suspense fallback={<Preloader />}>
          {
            (isAuthenticated && isAdmin)
                ? (
                    <Switch>
                      {/*<Route exact path="/" render={() => <Dashboard activeUsers={activeUsers} activeDevices={activeDevices} />} />*/}
                      <Route exact path="/" component={Dashboard} />
                      <Route exact path="/password_change" component={PasswordChange} />
                      <Route exact path="/previous-calls" component={PreviousCalls} />
                      {/* include admin routes */}
                      <Route exact path='/enroll_disenroll' component={EnrollDisenroll} />
                      <Route exact path='/add_user' component={AddUser} />
                      <Route exact path="/support" component={Support} />
                      <Route exact path="/support/:type" component={Support} />
                      <Route component={NotFound} />
                    </Switch>
                )
                : (
                    isAuthenticated
                        ? (
                            <Switch>
                              {/*<Route exact path="/" render={() => <Dashboard activeUsers={activeUsers} activeDevices={activeDevices} />} />*/}
                              <Route exact path="/" component={Dashboard} />
                              <Route exact path="/password_change" component={PasswordChange} />
                              <Route exact path="/previous-calls" component={PreviousCalls} />
                              <Route exact path="/support" component={Support} />
                              <Route exact path="/support/:type" component={Support} />
                              <Route component={NotFound} />
                            </Switch>
                        )
                        : (
                            <Switch>
                              <Route exact path="/" component={SignIn} />
                              <Route exact path='/password_reset' component={PasswordReset} />
                              <Route path='/sign-up/:token' component={SignUp} />
                              <Route component={NotFound} />
                            </Switch>
                        )
                )
          }
        </Suspense>
      </HashRouter>
  );
};
