import { createSlice } from '@reduxjs/toolkit';

export const videoChat = createSlice({
  name: 'videoChat',
  initialState: {
    isActive: false,
  },
  reducers: {
    openVideoChat: (state, { payload }) => ({
      isActive: true,
      sessionName: payload.sessionName,
    }),
    closeVideoChat: () => ({
      isActive: false,
    }),
    videoCallError: (state, { payload }) => ({ ...state, videoCallError: payload }),
    videoCallStart: (state) => ({ ...state, isVideoCallStart: true }),
    videoCallEnd: (state) => ({ ...state, isVideoCallStart: false }),
  },
});
// ---- action ----
export const { openVideoChat, closeVideoChat } = videoChat.actions;

export const selectCurrentCall = (state) => state.videoChat;
