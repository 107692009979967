import { createSlice } from '@reduxjs/toolkit';
import { devicesAPI } from '../../../services/devices';
import { userSignOut } from '../user';

export const devices = createSlice({
  name: 'devices',
  initialState: { devices: [], loading: false, error: false },
  reducers: {
    getDevicesRequested: (state) => ({
      ...state,
      loading: true,
    }),
    getDevicesFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
    }),
    getDevicesSuccess: (state, { payload }) => ({ loading: false, error: false, devices: payload }),
  },
  extraReducers: {
    [userSignOut]: () => ({ devices: [], loading: false, error: false }),
  },
});

// ---- actions ----
export const { getDevicesSuccess, getDevicesRequested, getDevicesFailure } = devices.actions;

// ---- request actions ----
export const getDevices = (payload) => async (dispatch) => {
  try {
    dispatch(getDevicesRequested());
    const response = await devicesAPI.getDevices(payload);
    if (response.data.error) {
      dispatch(getDevicesFailure(response.data.error));
    }
    if (response.status === 401) {
      dispatch(userSignOut());
    } else {
      dispatch(getDevicesSuccess(response.data));
    }
  } catch (e) {
    dispatch(getDevicesFailure(e.message));
  }
};

export const getDevicesByOrganizationId = (payload) => async (dispatch) => {
  try {
    dispatch(getDevicesRequested());
    const response = await devicesAPI.getDevicesByOrganizationId(payload);
    if (response.data.error) {
      dispatch(getDevicesFailure(response.data.error));
    }
    if (response.status === 401) {
      dispatch(userSignOut());
    } else {
      dispatch(getDevicesSuccess(response.data));
    }
  } catch (e) {
    dispatch(getDevicesFailure(e.message));
  }
};

// fetches all devices tied to a customer (and their organizations)
export const getDevicesByCustomerId = (payload) => async (dispatch) => {
  try {
    dispatch(getDevicesRequested());
    const response = await devicesAPI.getDevicesByCustomerId(payload);
    if (response.data.error) {
      dispatch(getDevicesFailure(response.data.error));
    }
    if (response.status === 401) {
      dispatch(userSignOut());
    } else {
      dispatch(getDevicesSuccess(response.data));
    }
  } catch (e) {
    dispatch(getDevicesFailure(e.message));
  }
};

// ---- selectors ----
export const selectDevices = (state) => state.devices.devices;
export const selectDevicesStatus = (state) => ({
  loading: state.devices.loading,
  error: state.devices.error,
});
