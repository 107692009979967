import { responseParse } from '../../utils/responseParse';
import { authHeader } from '../../utils/authHeader';
import { API_HOST } from '../../constants';

export const callSessionsApi = {
  getCallSessions: async () => {
    const requestGetOptions = {
      method: 'GET',
      mode: 'cors',
      headers: authHeader(),
    };

    const response = await fetch(`${API_HOST}/api/list_call/`, requestGetOptions);
    return responseParse(response);
  },
  getCallSessionEvents: async (payload) => {
    const requestGetOptions = {
      method: 'GET',
      mode: 'cors',
      headers: authHeader(),
    };

    const response = await fetch(`${API_HOST}/api/session_events/${payload}/`, requestGetOptions);
    return responseParse(response);
  },
};
