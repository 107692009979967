import { createSlice } from '@reduxjs/toolkit';
import { userSignOut } from '../user';
import { usersAPI } from '../../../services/users';

export const users = createSlice({
  name: 'users',
  initialState: { users: [], loading: false, error: false, isHost: true },
  reducers: {
    getUsersRequested: (state) => ({
      ...state,
      loading: true,
    }),
    getUsersFailure: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
    }),
    getUsersSuccess: (state, { payload }) => ({ loading: false, error: false, users: payload }),
    addUser: (state, { payload }) => ({
      loading: false,
      error: false,
      users: state.users.concat(payload),
    })
  },
  extraReducers: {
    [userSignOut]: () => ({ users: [], loading: false, error: false }),
  },
});

// ---- actions ----
export const {
  getUsersSuccess, getUsersRequested, getUsersFailure, addUser
} = users.actions;

export const getUsersByOrganizationId = (payload) => async (dispatch) => {
  try {
    dispatch(getUsersRequested());
    const response = await usersAPI.getUsersByOrganizationId(payload);
    if (response.data.error) {
      dispatch(getUsersFailure(response.data.error));
    }
    if (response.status === 401) {
      dispatch(userSignOut());
    } else {
      dispatch(getUsersSuccess(response.data));
    }
  } catch (e) {
    dispatch(getUsersFailure(e.message));
  }
};

export const createUser = (payload) => async (dispatch) => {
  try {
    dispatch(getUsersRequested());
    const response = await usersAPI.createUser(payload);
    if (response.status === 409) {
      dispatch(getUsersFailure(response.data.error));
    }
    if (response.status === 401) {
      dispatch(userSignOut());
    } else {
      dispatch(addUser(response.data));
    }
    return response;
  } catch (e) {
    dispatch(getUsersFailure(e.message));
  }
};

// ---- selectors ----
export const selectUsers = (state) => state.users.users;
export const selectUsersStatus = (state) => ({
  loading: state.users.loading,
  error: state.users.error,
});
